import React, { Component } from 'react'
import { graphql } from 'gatsby';
import Layout from './layout';

// Static Query
// Used anywhere, doesn't accept variable, can't use context

// Page Query
// Must be used on pages

export default class teamLayout extends Component {
  render() {
    // this next line is called "destucturing" 
    // see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment
    const { markdownRemark } = this.props.data;
    const { location } = this.props;
    return (
      <Layout location={location}>
        <h1>{markdownRemark.frontmatter.title}</h1>
        <p>{markdownRemark.frontmatter.bio}</p>
      </Layout>
    )
  }
}

// it is not clear where the 'data' above is coming from 
// data is what is between the first set of brackets {   }
// so data starts here: query TeamQuery($slug: String!) {

export const query = graphql`
  query TeamQuery($slug: String!) {
    markdownRemark(frontmatter: {
      slug: {
        eq: $slug
      }
    }) {
      frontmatter {
        title
        bio
        slug
      }
    }
  }
`;